import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import Image from '../../../assets/imgs/Water.webp';

const Water = () => {
  const isSmallScreen = useMediaQuery("(max-width:1209px)");
  const isVerySmallScreen = useMediaQuery("(max-width:700px)");
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={Image} alt='Water' style={{ marginRight: '30px', width: isSmallScreen ? isVerySmallScreen ? "100px" : '15%' : '13%', height: isSmallScreen ? isVerySmallScreen ? '100px' : '50%' : '24%' }} />
      <div>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '16px' : "30px" : "60px"} sx={{ marginTop: 3 }}>
          活性汚泥処理水とは
        </Typography>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '12px' : "20px" : "40px"} sx={{ marginTop: 3 }}>
          活性汚泥法において微生物によって浄化された上澄みの水のことを指す。
          <br />
          窒素を多く含む。
        </Typography>
      </div>
    </div>
  );
};

export default Water;
