import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import Image from '../../../assets/imgs/Sludge.webp';

const Sludge = () => {
  const isSmallScreen = useMediaQuery("(max-width:1209px)");
  const isVerySmallScreen = useMediaQuery("(max-width:700px)");
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={Image} alt='Cake' style={{ marginRight: '30px', width: isSmallScreen ? isVerySmallScreen ? "100px" : '14%' : '8%', height: isSmallScreen ? isVerySmallScreen ? '100px' : '50%' : '24%' }} />
      <div>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '16px' : "30px" : "60px"} sx={{ marginTop: 3 }}>
          活性汚泥とは
        </Typography>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '12px' : "20px" : "40px"} sx={{ marginTop: 3 }}>
          下水処理で有機物などを分解し、浄化する役割を果たしている
          <br />
          好気性微生物群のことを指す。
        </Typography>
      </div>
    </div>
  );
};

export default Sludge;
