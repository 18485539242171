import React, { useState } from 'react';
import { Toolbar, Typography, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => () => setIsOpen(open);

  return (
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Activated Sludge
      </Typography>
      <IconButton sx={{ color: 'black' }} aria-label="メニューを表示" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpen}
        aria-label="メニュー"
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: 250,
            backgroundColor: "#333333",
            color: "#ffffff",
          },
        }}
      >
        <MenuList sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MenuItem aria-label="ホーム">
            HOME
          </MenuItem>
          <MenuItem aria-label="私たちについて">
            ABOUT
          </MenuItem>
          <MenuItem aria-label="目標" >
            MISSION
          </MenuItem>
          <MenuItem aria-label="ニュース" >
            NEWS
          </MenuItem>
          <MenuItem aria-label="研究">
            RESEARCH
          </MenuItem>
          <MenuItem aria-label="連絡">
            CONTACT
          </MenuItem>
        </MenuList>
      </Drawer>
    </Toolbar>
  );
};

export default App;
