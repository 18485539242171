import React from 'react';
import { Paper } from '@mui/material';
import Sludge from '../components/main/contents/sludge';
import Cake from '../components/main/contents/cake';
import Water from '../components/main/contents/water';
import TopImage from '../components/main/topimage';
import Header from '../components/Header/Header';
import PaperAnimated from '../components/Animation/PaperAnimated';
const Home = () => {
  return (
    <div>
      <Header />
      <TopImage />
      <PaperAnimated direction="left">
        <Paper sx={{ padding: 2, marginBottom: 1, backgroundColor: "whitesmoke" }} key={"Sludge"}>
          <Sludge />
        </Paper>
      </PaperAnimated>
      <PaperAnimated direction="right">
        <Paper sx={{ padding: 2, marginBottom: 1, backgroundColor: "white" }} key={"water"}>
          <Cake />
        </Paper>
      </PaperAnimated>
      <PaperAnimated direction="left">
        <Paper sx={{ padding: 2, marginBottom: 1, backgroundColor: "whitesmoke" }} key={"Cake"}>
          <Water />
        </Paper>
      </PaperAnimated>
    </div>
  );
};

export default Home;
