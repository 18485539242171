import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, useMediaQuery } from '@mui/material';
import HeaderMenu from './HeaderMenu';
import HumbergerMenu from './HumbergerMenu';
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
});

const Header = () => {
  const isSmallScreen = useMediaQuery("(max-width:675px)");
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        {!isSmallScreen && <HeaderMenu />}
        {isSmallScreen && <HumbergerMenu />}
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
