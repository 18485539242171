import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const PaperAnimated = ({ children, direction }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    const xValue = direction === 'right' ? 6000 : -6000;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.from(entry.target, { x: xValue, duration: 1 });
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [direction]);

  return <div ref={elementRef}>{children}</div>;
};

export default PaperAnimated;
