import React from 'react';
import { Fade } from 'react-slideshow-image';
import { useMediaQuery, Typography } from '@mui/material';
import FadeInText from '../Animation/FadeInText';
import 'react-slideshow-image/dist/styles.css';
const fadeProperties = {
  duration: 2000,
  transitionDuration: 2000,
  infinite: true,
  indicators: false,
  arrows: false,
};
const fadeImages = [
  {
    url: './img/home/img1.webp'
  },
  {
    url: './img/home/img4.webp'
  }
];

const TopImage = () => {
  const isSmallScreen = useMediaQuery("(max-width:1135px)");

  return (
    <Fade {...fadeProperties}>
      {fadeImages.map((fadeImage, index) => (
        <div key={index} style={{ position: 'relative', width: '100vw', height: '93vh', overflow: 'hidden' }}>
          <img
            style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
            src={fadeImage.url}
            alt={fadeImage.caption}
          />
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Typography component={"div"} color={"white"} fontSize={isSmallScreen ? "25px" : "40px"} style={{ textShadow: '2px 2px 3px rgba(0, 0, 0, 1.0)' }}>
              <FadeInText text={isSmallScreen ? "自然を愛し、" : "環境を大切にすることが"} />
              <FadeInText text={isSmallScreen ? "未来を育てる。" : "私たちの未来への最上の努力か"} />
            </Typography>
          </div>
        </div>
      ))}
    </Fade>
  );
};

export default TopImage;
