import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import Image from '../../../assets/imgs/Cake.webp';

const Cake = () => {
  const isSmallScreen = useMediaQuery("(max-width:1209px)");
  const isVerySmallScreen = useMediaQuery("(max-width:700px)");

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '16px' : "30px" : "60px"} sx={{ marginTop: 3 }}>
          活性汚泥ケーキとは
        </Typography>
        <Typography fontSize={isSmallScreen ? isVerySmallScreen ? '12px' : "20px" : "40px"} sx={{ marginTop: 3 }}>
          活性汚泥法により生じた余剰汚泥を分解して、脱水後に残った固形分のことを指す。
          <br />
          微生物由来の窒素、リン酸を多く含む。
        </Typography>
      </div >
      <img src={Image} alt='Cake' style={{ marginLeft: '30px', width: isSmallScreen ? isVerySmallScreen ? "100px" : '50%' : '24%', height: isSmallScreen ? isVerySmallScreen ? '100px' : '50%' : '24%' }} />
    </div >
  );
};

export default Cake;
