import React from 'react';
import { Button, Toolbar, Typography } from '@mui/material';

const HeaderMenu = () => {
  return (
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Activated Sludge
      </Typography>
      <Button color="inherit">HOME</Button>
      <Button color="inherit">ABOUT</Button>
      <Button color="inherit">MISSION</Button>
      <Button color="inherit">NEWS</Button>
      <Button color="inherit">RESEARCH</Button>
      <Button color="inherit">CONTACT</Button>
    </Toolbar>
  );
}
export default HeaderMenu;